html {
  scroll-behavior: smooth;
}

.active-link {
  color: #038fd6 !important;
}

.logo {
  width: 100px;
}

.serv {
  height: 345px;
  width: 345px;
}

@media (min-width: 768px) {
  .mobile-screen {
    display: none;
  }

  .logo {
    width: 120px;
  }

  .serv {
    height: 460px;
    width: 460px;
  }
}

@media (max-width: 767px) {
  .desktop-screen {
    display: none;
  }
}
